import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
} from "@mui/material";

import { useIsFetching } from "@tanstack/react-query";
import { pick } from "ramda";
import { useState } from "react";
import { defineMessages } from "react-intl";
import { useMsg } from "../../components/Msg/Msg";
import { H2 } from "../../components/Typography";
import { useAuth, useMyMutation } from "../Authorization/AuthProvider";
import { Layout } from "../../components/Layout";

const messages = defineMessages({
  "notes.title": {
    id: "notes.title",
    defaultMessage: "Notes",
  },
});

const useNoteMutation = () =>
  useMyMutation({
    debug: true,
    // debug: "d",
    fetchDef: {
      method: "POST",
      url: `/api/latest/user-info/notes`,
      from: pick(["notes"]),
    },
    invalidate: { queryKey: ["user-info"] },
    snackbar: { success: false, error: true },
  });

const NotesPage = () => {
  const msg = useMsg({ dict: messages });
  const { user } = useAuth();
  const [note, setNote] = useState(user.data.notes || "");
  const noteMutation = useNoteMutation();
  const isFetchingUser = useIsFetching({ queryKey: ["user-info"] });
  const error = note.length > 500;

  const handleSave = (e) => {
    e.preventDefault();
    noteMutation.mutate({ notes: note });
  };

  return (
    <Layout header={{ heading: msg("notes.title") }}>
      <Card>
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <H2 sx={{ mb: 2 }}>{msg("dashboard.cards.notes.title")}</H2>
          <TextField
            sx={{
              flex: 1,
              width: "100%",
              fontSize: "1rem",
            }}
            variant="outlined"
            multiline
            placeholder={msg("dashboard.cards.notes.placeholder.empty")}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            error={error}
            helperText={error ? "Too long, max length is 500" : null}
          />

          <CircularProgress
            color="inherit"
            size={20}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              opacity: noteMutation.isPending ? 1 : isFetchingUser ? 0.1 : 0,
            }}
          />
        </CardContent>
        <Button
          variant="contained"
          sx={{ m: 2 }}
          disabled={error}
          onClick={handleSave}
        >
          Save
        </Button>
      </Card>
    </Layout>
  );
};

export { NotesPage };
