import { Card, CardContent, Divider } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRightMenu } from "../../components/Layout";
import { useMsg } from "../../components/Msg/Msg";
import { FeedbackRightMenu } from "./FeedbackRightMenu";
import { FormBuilderFields } from "./FormBuilderFields";
// import { FormBuilderMeta } from "./FormBuilderMeta";
import { DEFAULT_VALUES, FEEDBACK_FIELDS } from "./constants";
import { messages } from "./messages";
import {
  always,
  any,
  equals,
  evolve,
  identity,
  map,
  pick,
  pipe,
  prop,
  propEq,
  reject,
  trim,
} from "ramda";
import { useFeedbackFormsQuery } from "./api";
import { generalMessages } from "../../components/messages";
import { Icon } from "../../components/Icon";
import { RHFTextField } from "../../components/Forms";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { useFeedbackOptions } from "./useFeedbackQuestionOptionsDict";
import { unstable_useBlocker } from "react-router-dom";
import { ConfirmModal } from "../Modal/ConfirmModal";

// const onSubmit = (data, e) => console.log("[onSubmit]", data, e);
const onError = (errors, e) => console.log("[onError]", { errors, e });

const useBlockBrowserNav = ({
  shouldBlock,
  message = "You have unsaved changes",
}) => {
  useEffect(() => {
    if (shouldBlock) {
      window.onbeforeunload = () => message;
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [message, shouldBlock]);
};

const useBlockNavigationPopup = ({
  canNavigateRef,
  isActive,
  iconName = "DeleteOutlined",
  title = "You have unsaved changes",
  desc = "",
  leaveLabel = "Leave anyway",
  closeLabel = "Return back to form",
}) => {
  useBlockBrowserNav({
    message: title,
    shouldBlock: isActive && !canNavigateRef.current,
  });

  const blocker = unstable_useBlocker(
    ({ currentLocation, nextLocation }) =>
      !canNavigateRef?.current &&
      isActive &&
      currentLocation.pathname !== nextLocation.pathname
  );

  ConfirmModal.useModal(
    useMemo(() => {
      return {
        open: blocker.state === "blocked",
        iconName,
        title,
        desc,
        // error,
        onClose: () => blocker.reset(),
        getButtons: ({ onClose }) => [
          {
            variant: "contained",
            color: "error",
            type: "button",
            children: leaveLabel,
            onClick: () => blocker.proceed(),
          },
          {
            variant: "contained",
            type: "button",
            children: closeLabel,
            onClick: () => onClose(),
          },
        ],
      };
    }, [blocker, closeLabel, desc, iconName, leaveLabel, title])
  );

  return { blocker };
};

export const CreateFeedbackForm = ({
  isCopy,
  isEdit,
  editFormQuery,
  values = {},
  onShareForm,
  onSaveDraft,
  isPending,
  isSavingDraft,
  collected,
  allowNavigationRef,
}) => {
  const msg = useMsg({ dict: messages });
  const generalMsg = useMsg({ dict: generalMessages });
  const form = useForm({
    // Both defined - error (uncontrolled -> controlled) in console
    defaultValues: values,
    values,
  });
  useBlockNavigationPopup({
    canNavigateRef: allowNavigationRef,
    isActive: form.formState.isDirty,
    title: msg("feedback.unsaved-changes.title"),
    desc: msg.maybe("feedback.unsaved-changes.desc") || "",
    closeLabel: msg("feedback.unsaved-changes.close"),
    leaveLabel: msg("feedback.unsaved-changes.leave"),
  });
  const fields = form.watch(FEEDBACK_FIELDS.fields);
  const count = fields?.length;
  const requiredCount = fields?.filter((field) => field.required)?.length;
  const _onShareForm = useCallback(
    (values) => onShareForm?.(values),
    [onShareForm]
  );
  const _onSaveDraft = useCallback(
    (values) => onSaveDraft?.(values),
    [onSaveDraft]
  );
  const feedbacksQuery = useFeedbackFormsQuery();
  const existingTitles = useMemo(() => {
    if (!feedbacksQuery.data?.length) return [];
    else
      return pipe(
        isEdit ? reject(propEq(values.id, "id")) : identity,
        map(prop(FEEDBACK_FIELDS.title))
      )(feedbacksQuery.data);
  }, [feedbacksQuery.data, values?.id, isEdit]);

  const { query: optionsQuery } = useFeedbackOptions();

  useRightMenu(
    useMemo(
      () => (
        <FeedbackRightMenu
          isPending={editFormQuery.isFetching}
          collected={collected}
          stats={[
            { label: msg("feedback.create.stats.questions"), value: count },
            {
              label: msg("feedback.create.stats.required"),
              value: requiredCount,
            },
          ]}
          secondaryButtonProps={{
            endIcon: <Icon name="ArchiveOutlined" />,
            children: generalMsg("general.save-draft"),
            onClick: form.handleSubmit(_onSaveDraft, onError),
            disabled: isPending || !optionsQuery.data,
            loading: isSavingDraft,
            loadingPosition: "end",
          }}
          buttonProps={{
            endIcon: <Icon name="SendOutlined" />,
            children: msg("feedback.create.next-btn"),
            onClick: form.handleSubmit(_onShareForm, onError),
            disabled: isPending || !optionsQuery.data,
            // loadingPosition: "end",
          }}
        />
      ),
      [
        editFormQuery.isFetching,
        collected,
        msg,
        count,
        requiredCount,
        generalMsg,
        form,
        _onSaveDraft,
        isPending,
        optionsQuery.data,
        isSavingDraft,
        _onShareForm,
      ]
    )
  );

  console.log("[CreateFeedbackForm.rndr]", {
    "form.formState.isDirty": form.formState.isDirty,
    values,
  });

  return (
    <FormProvider {...form}>
      <QueryRenderer
        query={editFormQuery}
        alwaysSuccess={!isEdit}
        loaderEl={<Loaders.CircularBlock spaced />}
        success={() => (
          <>
            <Card>
              <CardContent>
                <RHFTextField
                  isLoading={(isEdit || isCopy) && editFormQuery.isFetching}
                  name={FEEDBACK_FIELDS.title}
                  placeholder={msg("feedback.create.title.placeholder")}
                  parametrizedValidate={[
                    ["required"],
                    ["notBlank"],
                    [
                      "forbiddenValues",
                      { forbiddenList: existingTitles },
                      { tsKey: "feedback.create.title.error.exists" },
                    ],
                  ]}
                  variant="standard"
                  fullWidth
                  autoFocus
                  // debug={{ msg: "OMG" }}
                  // sx={{ mt: 3 }}
                />
                <RHFTextField
                  isLoading={(isEdit || isCopy) && editFormQuery.isFetching}
                  name={FEEDBACK_FIELDS.description}
                  placeholder={msg("feedback.create.description.placeholder")}
                  rules={{}}
                  variant="standard"
                  fullWidth
                  sx={{ mt: 3 }}
                />
              </CardContent>
            </Card>
            <Divider sx={{ my: 3 }} />
            {/* TODO: with initial values (edit) and without this row, autocomplete is rendered without options, containing just key, not label */}
            {!optionsQuery.data ? (
              <Loaders.CircularBlock spaced />
            ) : (
              <FormBuilderFields name={FEEDBACK_FIELDS.fields} />
            )}
          </>
        )}
      />
    </FormProvider>
  );
};
