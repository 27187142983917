import { IconButton, Tooltip } from "@mui/material";
import { Icon } from "../../../components/Icon";
import { useMsg } from "../../../components/Msg/Msg";
import { messages } from "../messages";
import { useFavorites } from "./useFavorites";

export const FavoriteButton = ({ username }) => {
  const msg = useMsg({ dict: messages });
  const { favoritesMaybe, addCoach, removeCoach, mutationPending } =
    useFavorites({ id: username });

  if (!username) return null;
  const isFavorite = favoritesMaybe?.includes?.(username);
  const setFavorite = (isNewFavorite) => {
    if (!favoritesMaybe) return;
    if (isNewFavorite) return addCoach(username);
    return removeCoach(username);
  };
  const bookmarkLabel = isFavorite
    ? msg("coaches.coach.bookmark.remove")
    : msg("coaches.coach.bookmark.add");

  return (
    <Tooltip title={bookmarkLabel}>
      <IconButton
        aria-label={bookmarkLabel}
        variant={isFavorite ? "contained" : "outlined"}
        size="sm"
        onClick={() => setFavorite(!isFavorite)}
        disabled={!favoritesMaybe || mutationPending}
      >
        {!isFavorite ? (
          <Icon name="BookmarkAddOutlined" />
        ) : (
          <Icon name="BookmarkAdded" />
        )}
      </IconButton>
    </Tooltip>
  );
};
