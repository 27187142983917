import {
  Box,
  Button,
  CardActionArea,
  Chip,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import {
  always,
  any,
  applySpec,
  findIndex,
  identity,
  ifElse,
  is,
  lt,
  map,
  omit,
  pipe,
  prop,
  propEq,
  propSatisfies,
  unless,
} from "ramda";
import { useEffect, useMemo, useState } from "react";
import { Icon } from "../../../components/Icon";
import { Msg } from "../../../components/Msg";
import { useMsg } from "../../../components/Msg/Msg";
import { H2, P } from "../../../components/Typography";
import { primary25, primary500 } from "../../../theme";
import { useAuth } from "../../Authorization";
import { useMyQuery } from "../../Authorization/reactQueryWrappers";
import { useStrengths } from "../../Strengths/talents";
import { SessionStepCard } from "../SessionStepCard";
import { useAreasDict } from "../areas";
import { SESSION_FIELDS } from "./constants";

const useOldAreasRecommendations = () => {
  const { areas: areasDict } = useAreasDict();
  const areasArr = Object.entries(areasDict).map(([key, value]) => ({
    key,
    name: value.label,
    label: value.label,
  }));
  return areasArr;
};

export const useArea = ({ valueArr }) => {
  // Area saved as array for some reason
  const value = valueArr?.length ? valueArr[0] : "";
  const areasArr = useOldAreasRecommendations();

  // Previously areas were saved as IDs, now they are saved as names
  // TODO: check in DB that no area is saved as number and remove
  const areaMaybe = areasArr.find((area) => area.key === value); // backward compatibility
  const areaText = areaMaybe?.name || value;

  return {
    areaText,
  };
};

const AreaTile = ({
  title,
  items,
  itemsLoading,
  selectedAreaName,
  onItemSelect,
  fallback,
  isTileSelected = true,
  onClick,
}) => {
  return (
    <CardActionArea
      component="div"
      disableRipple
      onClick={onClick}
      sx={{ p: 3, bgcolor: "#FCFCFD", borderRadius: "4px", minHeight: "190px" }}
    >
      <H2 sx={{ mb: 3, color: isTileSelected ? primary500 : "#344054" }}>
        {title}
      </H2>
      {!isTileSelected ? null : itemsLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : !items?.length ? (
        <P>{fallback}</P>
      ) : (
        <SelectableTags
          items={items}
          selected={selectedAreaName}
          onSelect={onItemSelect}
        />
      )}
    </CardActionArea>
  );
};

const SelectableTags = ({ items, selected, onSelect }) => {
  const selectedSx = {
    color: primary500,
    backgroundColor: primary25,
    border: `1px solid ${primary500}`,
  };

  return (
    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
      {items.map(({ name, label }) => (
        <Chip
          key={name}
          label={label}
          sx={{
            borderRadius: "6px",
            backgroundColor: "#F9F8FF",
            ...(selected === name ? selectedSx : {}),
          }}
          onClick={(e) => {
            onSelect({ name, label });
            e.stopPropagation();
          }}
        />
      ))}
    </Box>
  );
};

export const AreaStep = ({
  handleNext,
  data,
  setData,
  step: { fieldDefMap, ...step },
  stepper,
}) => {
  const msg = useMsg();
  const { user } = useAuth();
  const topStrengths = useStrengths({
    keys: user?.data?.strengths?.slice(0, 5),
  });
  const strengthsCount = user?.data?.strengths?.length || 0;
  const bottomStrengthKeys =
    strengthsCount >= 15
      ? user?.data?.strengths?.slice(-10)
      : strengthsCount >= 10
      ? user?.data?.strengths?.slice(-5)
      : [];
  const bottomStrengths = useStrengths({
    keys: bottomStrengthKeys,
  });
  const oldAreas = useOldAreasRecommendations();
  const areasAIQuery = useMyQuery({
    fetchDef: {
      method: "GET",
      url: `/api/latest/user-sessions/generate-recommended-growth`,
      to: pipe(
        unless(
          is(Array),
          always([])
          // always([{ recommendation: "Communication", area: "Communication" }])
        ),
        map(
          applySpec({
            key: prop("recommendation"),
            name: prop("recommendation"),
            label: prop("recommendation"),
            // area: prop("area"),
          })
        )
      ),
    },
  });

  const tiles = useMemo(
    () => [
      {
        title: msg("sessions.new.steps.area.strengths.title"),
        items: topStrengths,
        fallback: msg("sessions.new.steps.area.fallback.title"),
      },
      {
        title: msg("sessions.new.steps.area.weaknesses.title"),
        items: bottomStrengths,
        fallback: msg("sessions.new.steps.area.fallback.title"),
      },
      {
        title: msg("sessions.new.steps.area.recommended.title"),
        items: areasAIQuery.isPending
          ? []
          : areasAIQuery.data?.length
          ? areasAIQuery.data
          : oldAreas,
        itemsLoading: areasAIQuery.isPending,
        fallback: null,
      },
    ],
    [
      areasAIQuery.data,
      areasAIQuery.isPending,
      bottomStrengths,
      msg,
      oldAreas,
      topStrengths,
    ]
  );
  const valueArr = data[SESSION_FIELDS.AREA_OF_DEVELOPMENT];
  const { areaText } = useArea({ valueArr });

  const derivedSelectedTileIndex = pipe(
    findIndex(propSatisfies(any(propEq(areaText, "name")), "items")),
    ifElse(lt(0), identity, always(0))
  )(tiles);
  const [selectedTileIndex, setSelectedTileIndex] = useState(
    derivedSelectedTileIndex
  );
  useEffect(() => {
    setSelectedTileIndex(derivedSelectedTileIndex);
  }, [derivedSelectedTileIndex]);

  const [selectedArea, setSelected] = useState(areaText || "");
  const newArea = selectedArea?.trim();
  const field = fieldDefMap[SESSION_FIELDS.AREA_OF_DEVELOPMENT];
  const areaValue = (field?.map || identity)(newArea);
  const next = () => {
    handleNext({ [SESSION_FIELDS.AREA_OF_DEVELOPMENT]: areaValue });
  };

  const createOnSelect = (key) => () => {
    setSelectedTileIndex(key);
  };
  const onItemSelect = (item) => {
    setSelected(item?.name);
  };
  const onCustomAreaChange = (value) => {
    setSelected(value);
  };

  console.log("[AreaStep.rndr]", {
    areaText,
    tiles,
    oldAreas,
    "areasAIQuery.data": areasAIQuery.data,
    data,
    selectedArea,
    step,
    stepper,
    selectedTileIndex,
    topStrengths,
    bottomStrengths,
  });

  const gridItemProps =
    tiles.length === 3
      ? { xs: 12, md: 6, lg: 4 }
      : tiles?.length === 2
      ? { xs: 12, md: 6 }
      : { xs: 12 };

  return (
    <SessionStepCard
      {...{
        step: tiles?.length === 3 ? step : omit(["perex"], step),
        stepper,
      }}
    >
      <Grid container spacing={3} sx={{ my: 3 }}>
        {tiles.map(({ items, itemsLoading, title, fallback }, index) => (
          <Grid key={title} item {...gridItemProps}>
            <AreaTile
              title={title}
              items={items}
              itemsLoading={itemsLoading}
              selectedAreaName={selectedArea}
              onItemSelect={onItemSelect}
              fallback={fallback}
              isTileSelected={selectedTileIndex === index}
              onClick={createOnSelect(index)}
            />
          </Grid>
        ))}
        {/* <Grid item {...gridItemProps}> <AreaTile title={msg("sessions.new.steps.area.strengths.title")} items={tilesItems[0]} selectedAreaName={selectedArea} onItemSelect={onItemSelect} fallback={msg("sessions.new.steps.area.fallback.title")} isTileSelected={selectedTileIndex === 0} onClick={createOnSelect(0)} /> </Grid>
        <Grid item {...gridItemProps}> <AreaTile title={msg("sessions.new.steps.area.weaknesses.title")} items={tilesItems[1]} selectedAreaName={selectedArea} onItemSelect={onItemSelect} fallback={msg("sessions.new.steps.area.fallback.title")} isTileSelected={selectedTileIndex === 1} onClick={createOnSelect(1)} /> </Grid>
        <Grid item {...gridItemProps}> <AreaTile title={msg("sessions.new.steps.area.recommended.title")} items={tilesItems[2]} selectedAreaName={selectedArea} onItemSelect={onItemSelect} fallback={null} isTileSelected={selectedTileIndex === 2} onClick={createOnSelect(2)} /> </Grid> */}
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "baseline",
          gap: 5,
        }}
      >
        <TextField
          // autoFocus
          required
          placeholder={msg("sessions.new.steps.area.customarea.placeholder")}
          name="customArea"
          size="small"
          hiddenLabel
          value={selectedArea}
          onChange={(e) => {
            onCustomAreaChange(e.target.value);
          }}
          sx={{ flex: "1 1 auto" }}
        />
        <Button
          type="submit"
          variant="contained"
          endIcon={<Icon name="ArrowForward" />}
          onClick={next}
          disabled={!newArea}
        >
          <Msg id="sessions.new.steps.area.next" />
        </Button>
      </Box>
    </SessionStepCard>
  );
};
