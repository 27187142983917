import { intersection } from "ramda";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useAuth } from "./";

export const AuthRedirect = () => {
  let { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to={routes.signIn} replace />;
  } else {
    return <Navigate to={routes.dashboard} replace />;
  }
};

export const useHasRequiredRoles = ({ anyOf = [] } = {}) => {
  const { user, isLoggedIn } = useAuth();

  if (!anyOf.length) return true;
  if (!isLoggedIn) return false;

  return intersection(user?.data?.userRoles, anyOf).length > 0;
};

export const RequireAuth = ({ children, rolesDef }) => {
  const { user, isLoggedIn } = useAuth();
  const location = useLocation();
  const hasRole = useHasRequiredRoles(rolesDef);

  const [lastUsername, setLastUsername] = useState("");
  const username = user?.data?.username;
  useEffect(() => {
    if (username) setLastUsername(username);
  }, [username]);

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={routes.signIn}
        state={{ from: location, lastUsername }}
        replace
      />
    );
  }

  if (hasRole) return children;
  else return <Navigate to={routes.dashboard} replace />;
};

const getAuthorizedLocationRedirect = ({ locationState, currentUsername }) => {
  // const isSavedPathValid = locationState.lastUsername === currentUsername;
  const savedPathname = [
    locationState.from?.pathname || "",
    locationState.from?.search || "",
  ].join("");
  // const savedPathname = isSavedPathValid ? locationState.from?.pathname : "";
  // debugger;
  return savedPathname || routes.dashboard;
};

export const ForbidAuth = ({ children }) => {
  const { user, isLoggedIn } = useAuth();
  const location = useLocation();

  if (isLoggedIn) {
    const to = getAuthorizedLocationRedirect({
      locationState: location.state || {},
      currentUsername: user.data.username, // depends on GlobalLoader
    });

    return <Navigate to={to} state={{ from: location }} replace />;
  }

  return children;
};
