import React from "react";
import { InfoBox } from "../../components/InfoBox";
import { useMsg } from "../../components/Msg/Msg";
import { ScrollableRightMenu } from "../../components/ScrollableRightMenu";
import { P } from "../../components/Typography";
import { messages } from "./messages";

export const CoachesRightMenu = () => {
  const msg = useMsg({ dict: messages });

  const EXPECT_ITEMS = [
    // {
    //   heading: msg("coaches.aside.items.1.heading"),
    //   iconName: "CreditCard",
    //   text: msg("coaches.aside.items.1.text"),
    // },
    {
      heading: msg("coaches.aside.items.2.heading"),
      iconName: "Star",
      text: msg("coaches.aside.items.2.text"),
    },
    {
      heading: msg("coaches.aside.items.3.heading"),
      iconName: "Lock",
      text: msg("coaches.aside.items.3.text"),
    },
  ];

  return (
    <ScrollableRightMenu heading={msg("coaches.aside.title")}>
      {EXPECT_ITEMS.map(({ heading, iconName, text }) => (
        <InfoBox
          key={heading}
          heading={heading}
          iconName={iconName}
          color="primary"
          sx={{ p: 2, mb: 3, borderRadius: "6px" }}
        >
          <P>{text}</P>
        </InfoBox>
      ))}
    </ScrollableRightMenu>
  );
};
